<template>
  <CyMenu
    v-model="isFilterOpened"
    content-class="filter"
    :close-on-content-click="false"
    :nudge-width="200"
    :left="$vuetify.breakpoint.width < 1035"
    offset-y>
    <template #activator="{ on }">
      <span>
        <CyButton
          theme="primary"
          variant="tertiary"
          icon="arrow_drop_down"
          icon-append
          v-on="on"
          @click="setTags">
          {{ label }}
        </CyButton>
      </span>
    </template>
    <v-card>
      <v-text-field
        v-model.trim="searchedTag"
        class="filter__search search-field"
        append-icon="search"
        clearable
        hide-details
        single-line/>
      <v-row
        no-gutters
        class="filter__list filter__list--virtual">
        <v-col :class="[{ 'filter__list--loading': fetchInProgress }]">
          <v-progress-circular
            v-if="fetchInProgress"
            indeterminate
            color="secondary"/>
          <div v-else>
            <v-list class="pt-0">
              <v-virtual-scroll
                :items="filteredTags"
                bench="10"
                class="mt-0"
                item-height="48">
                <template #default="{ item: tag }">
                  <v-list-item
                    :key="tag.value"
                    @click.capture.stop="selectTag(tag)">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="tags"
                        class="filter__list__checkbox"
                        multiple
                        :value="tag"
                        color="secondary"/>
                    </v-list-item-action>
                    <v-list-item-content @click.stop>
                      <v-list-item-title :class="[{ 'font-weight-bold': _.includes(tags, tag) }]">
                        {{ tag.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <CyButton
          theme="primary"
          variant="tertiary"
          @click="setEventTags">
          {{ $t('forms.clearAll') }}
        </CyButton>

        <v-spacer/>

        <CyButton
          theme="primary"
          variant="tertiary"
          @click="close">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          variant="tertiary"
          @click="apply">
          {{ $t('forms.btnApply') }}
        </CyButton>
      </v-card-actions>
    </v-card>
  </CyMenu>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'CyDataTableTagsFilter',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFilterOpened: false,
    tags: [],
    searchedTag: null,
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    ...mapState('organization', {
      availableTags: (state) => state.available.tags,
      fetchInProgress: (state) => state.fetchInProgress.tags,
    }),
    tagsList () {
      return _.flatMap(this.availableTags, (tags, key) => tags.map((tag) => ({ value: `${key}: ${tag}`, key, tag })))
    },
    tagsListValues () {
      return _.map(this.tagsList, 'value')
    },
    filteredTags () {
      return _.isNull(this.searchedTag)
        ? this.tagsList
        : _.filter(this.tagsList, ({ value }) => value.toLowerCase().includes(this.searchedTag.toLowerCase()))
    },
    tagsToQueryParams () {
      return _(this.tags)
        .groupBy('key')
        .transform((result, tags, key) => { result[`${key}[in]`] = _.map(tags, ({ tag }) => tag).toString() })
        .value()
    },
    tagsFromQueryParams () {
      const { begin, end, type, severity, ...currentTags } = { ...this.getDataTableFilters(this.$route.name) }

      return _(currentTags)
        .transform((result, value, key) => {
          value.split(',').forEach((tag) => {
            const param = key.split('[')[0]
            result.push({ value: `${param}: ${tag}`, key: param, tag })
          })
        }, [])
        .value()
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'tags' })
    this.setTags()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    setEventTags () {
      const eventTypeTags = this.tags.filter((tag) => !this.tagsListValues.includes(tag.value))
      this.tags = eventTypeTags
    },
    setTags () {
      this.tags = this.tagsFromQueryParams
    },
    selectTag (clickedTag) {
      const isTagSelected = this.tags.some((tag) => tag.value === clickedTag.value)

      if (isTagSelected) {
        const indexOfClickedTag = this.tags.findIndex(({ value }) => value === clickedTag.value)
        this.tags.splice(indexOfClickedTag, 1)
      } else {
        this.tags.push(clickedTag)
      }
    },
    apply () {
      const { $route: { name } } = this
      const { begin, end } = this.getDataTableFilters(name)

      this.SET_DATA_TABLE_FILTERS({
        name,
        filters: { begin, end, ...this.tagsToQueryParams },
      })
      this.close()
    },
    close () {
      this.searchedTag = null
      this.isFilterOpened = false
    },
  },
}
</script>
