import { render, staticRenderFns } from "./date-filter.vue?vue&type=template&id=2c1d7b55&scoped=true"
import script from "./date-filter.vue?vue&type=script&lang=js"
export * from "./date-filter.vue?vue&type=script&lang=js"
import style0 from "./date-filter.vue?vue&type=style&index=0&id=2c1d7b55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1d7b55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardActions,VDatePicker,VSpacer,VTextField})
