<template>
  <CyMenu
    v-model="isFilterOpened"
    :close-on-content-click="false"
    :nudge-width="200"
    :left="$vuetify.breakpoint.width < 1035"
    content-class="filter"
    offset-y>
    <template #activator="{ on }">
      <span>
        <CyButton
          theme="primary"
          variant="tertiary"
          icon="arrow_drop_down"
          icon-append
          data-cy="filter-cr-button"
          v-on="on"
          @click="setItems">
          {{ label }}
        </CyButton>
      </span>
    </template>
    <v-card class="items">
      <v-text-field
        v-if="hasSearch && !_.some(_.values(fetchInProgress))"
        v-model.trim="searchedItem"
        class="filter__search search-field"
        append-icon="search"
        data-cy="search-field"
        clearable
        hide-details
        single-line/>
      <v-row
        no-gutters
        class="filter__list">
        <v-col :class="[{ 'filter__list--loading': _.some(_.values(fetchInProgress)) }]">
          <v-progress-circular
            v-if="_.some(_.values(fetchInProgress))"
            indeterminate
            color="secondary"/>
          <v-list
            v-else
            class="pt-0">
            <v-virtual-scroll
              :items="filteredItems"
              :item-height="48"
              :height="height">
              <template #default="{ item }">
                <v-list-item
                  data-cy="cr-list-item"
                  @click.capture.stop="selectItem(item)">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="items"
                      class="filter__list__checkbox"
                      multiple
                      :value="item"
                      color="secondary"/>
                  </v-list-item-action>
                  <slot
                    name="item-content"
                    :item="item"
                    :items="items">
                    <v-list-item-content @click.stop>
                      <v-list-item-title :class="[{ 'font-weight-bold': _.includes(items, item) }]">
                        {{ getDisplayName(item) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </slot>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>
        </v-col>
      </v-row>
      <v-card-actions>
        <CyButton
          theme="primary"
          variant="tertiary"
          @click="clearAll">
          {{ $t('forms.clearAll') }}
        </CyButton>
        <v-spacer/>
        <CyButton
          theme="primary"
          variant="tertiary"
          @click="close">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          variant="tertiary"
          data-cy="filter-apply-btn"
          @click="apply">
          {{ $t('forms.btnApply') }}
        </CyButton>
      </v-card-actions>
    </v-card>
  </CyMenu>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'CyDataTableSelectFilter',
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: [Number, String],
      default: '248',
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFilterOpened: false,
    items: [],
    searchedItem: null,
  }),
  computed: {
    ...mapState('organization', {
      fetchInProgress: (state) => state.fetchInProgress,
    }),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    filteredItems () {
      return _.isNull(this.searchedItem)
        ? this.options.items
        : _.filter(this.options.items, ({ title }) => title.toLowerCase().includes(this.searchedItem.toLowerCase()))
    },
    currentFilters () {
      const filters = { ...this.getDataTableFilters(this.$route.name) }
      const allSelectedValues = []

      for (const queryParam of this.queryParams(this.options.items)) {
        for (const filterValues of _.$get(filters, `${queryParam}[in]`, '').split(',')) {
          if (!_.$isEmpty(filterValues)) allSelectedValues.push(filterValues)
        }
      }
      return allSelectedValues
    },
    hasSearch () {
      return _.get(this.options, 'search', true) // don't change to $get pls
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    clearAll () {
      this.items = []
    },
    setItems () {
      this.items = _.map(this.currentFilters, (selectedValue) => _.find(this.options.items, ({ value }) => selectedValue.includes(value)))
    },
    selectItem (item) {
      _.some(this.items, ['value', item.value])
        ? this.items.splice(_.findIndex(this.items, ({ value }) => value === item.value), 1)
        : this.items.push(item)
    },
    getDisplayName (item) {
      return item.display || item.title
    },
    apply () {
      const { items, $route: { name } } = this
      const filters = { ...this.getDataTableFilters(name) }
      for (const queryParam of this.queryParams(this.options.items)) {
        const stringifiedFilterValues = items.filter(({ value, key }) => key === queryParam).map(({ value }) => value).toString()
        if (!_.$isEmpty(stringifiedFilterValues)) filters[`${queryParam}[in]`] = stringifiedFilterValues
        else delete filters[`${queryParam}[in]`]
      }

      this.SET_DATA_TABLE_FILTERS({ name, filters })
      this.close()
    },
    queryParams (items = []) {
      return _.uniq(_.reduce(items, (result, { key }) => key && [...result, key], []))
    },
    close () {
      this.searchedItem = null
      this.$toggle.isFilterOpened(false)
    },
  },
}
</script>
