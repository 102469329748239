<template>
  <CyMenu
    v-if="canSeeMembers"
    v-model="isFilterOpened"
    content-class="filter"
    :close-on-content-click="false"
    :nudge-width="200"
    :left="$vuetify.breakpoint.width < 1035"
    offset-y>
    <template #activator="{ on }">
      <span>
        <CyButton
          theme="primary"
          variant="tertiary"
          data-cy="filter-button"
          icon="arrow_drop_down"
          icon-append
          v-on="on"
          @click="setOwners">
          {{ label }}
        </CyButton>
      </span>
    </template>
    <v-card>
      <v-text-field
        v-model.trim="searchedOwner"
        class="filter__search"
        append-icon="search"
        data-cy="filter-search-input"
        clearable
        hide-details
        single-line/>
      <div
        :class="['filter__list', { 'filter__list--loading': fetchInProgress }]">
        <v-progress-circular
          v-if="fetchInProgress"
          indeterminate
          color="secondary"/>
        <v-list v-else>
          <v-list-item
            v-for="owner of filteredOwners"
            :key="owner.id"
            data-cy="owner-list-item"
            :class="['filter__list__item', { 'filter__list__item--current-user': isCurrentUser(owner.username) }]"
            @click.capture.stop="selectOwners(owner)">
            <v-list-item-action>
              <v-checkbox
                v-model="owners"
                class="filter__list__checkbox"
                multiple
                data-cy="owner-checkbox"
                :value="owner"
                color="secondary"/>
            </v-list-item-action>
            <CyMember
              :member="owner"
              :active="_.includes(owners, owner)"/>
          </v-list-item>
        </v-list>
      </div>
      <v-card-actions>
        <CyButton
          theme="primary"
          variant="tertiary"
          data-cy="filter-clear-btn"
          @click="clearAll">
          {{ $t('forms.clearAll') }}
        </CyButton>

        <v-spacer/>

        <CyButton
          theme="primary"
          variant="tertiary"
          data-cy="filter-cancel-btn"
          @click="close">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          variant="tertiary"
          data-cy="filter-apply-btn"
          @click="apply">
          {{ $t('forms.btnApply') }}
        </CyButton>
      </v-card-actions>
    </v-card>
  </CyMenu>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'CyDataTableOwnersFilter',
  props: {
    options: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFilterOpened: false,
    owners: [],
    searchedOwner: null,
  }),
  computed: {
    ...mapGetters(['orgMembers']),
    ...mapGetters('layout', ['getDataTableFilters']),
    ...mapState({
      fetchInProgress: (state) => state.organization.fetchInProgress.members,
    }),
    filteredOwners () {
      return _.isEmpty(this.searchedOwner)
        ? this.orgMembers
        : this.orgMembers.filter(({ family_name: firstName, given_name: surname, username, email }) => {
          return [firstName, surname, username, email].some((field) => _.isEmpty(this.searchedOwner) || field.toLowerCase().includes(this.searchedOwner.toLowerCase()))
        })
    },
    canSeeMembers () {
      return this.$cycloid.permissions.canDisplay('GetOrgMembers')
    },
  },
  async mounted () {
    if (!this.canSeeMembers) return
    await this.FETCH_AVAILABLE({ keyPath: 'members' })
    this.setOwners()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    clearAll () {
      this.owners = []
    },
    setOwners () {
      const filters = { ...this.getDataTableFilters(this.$route.name) }
      const userIds = _.$get(filters, `${this.options.queryParams[0]}[in]`, '').split(',').map((id) => Number(id))
      this.owners = _.filter(this.orgMembers, ({ id }) => userIds.includes(id))
    },
    selectOwners (owner) {
      _.some(this.owners, ['id', owner.id])
        ? this.owners.splice(_.findIndex(this.owners, ({ id }) => id === owner.id), 1)
        : this.owners.push(owner)
    },
    apply () {
      const { owners, options: { queryParams }, $route: { name } } = this
      const filters = { ...this.getDataTableFilters(name) }

      filters[`${queryParams[0]}[in]`] = owners.map(({ id }) => id).toString()

      this.SET_DATA_TABLE_FILTERS({ name, filters })
      this.close()
    },
    isCurrentUser (ownersListUsername) {
      return this.username === ownersListUsername
    },
    close () {
      this.searchedOwner = null
      this.isFilterOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  .data-table-inline-placeholder,
  .data-table-inline-image {
    width: 40px;
    height: 40px;
    margin-top: 0;
    line-height: 40px;
  }
}
</style>
