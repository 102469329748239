<template>
  <CyMenu
    v-model="filterOpened"
    :close-on-content-click="false"
    :nudge-width="200"
    :left="$vuetify.breakpoint.width < 1035"
    offset-y>
    <template #activator="{ on }">
      <span>
        <CyButton
          theme="primary"
          variant="tertiary"
          icon="arrow_drop_down"
          icon-append
          v-on="on">
          {{ label }}
        </CyButton>
      </span>
    </template>

    <v-card class="created-date">
      <div class="d-flex flex-column pa-6 mx-2">
        <CyMenu
          v-model="startDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template #activator="{ on }">
            <v-text-field
              v-model="startDate"
              :label="$t('startDate')"
              prepend-icon="event"
              readonly
              clearable
              v-on="on"
              @click:clear="clear('startDate')"/>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="closeDatePicker('startDatePicker')"/>
        </CyMenu>
        <v-spacer/>

        <CyMenu
          v-model="endDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template #activator="{ on }">
            <v-text-field
              v-model="endDate"
              :label="$t('endDate')"
              prepend-icon="event"
              readonly
              clearable
              v-on="on"
              @click:clear="clear('endDate')"/>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="closeDatePicker('endDatePicker')"/>
        </CyMenu>
        <v-spacer/>
      </div>
      <v-card-actions>
        <v-spacer/>

        <CyButton
          theme="primary"
          variant="tertiary"
          @click="filterOpened = false">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          variant="tertiary"
          @click="apply">
          {{ $t('forms.btnApply') }}
        </CyButton>
      </v-card-actions>
    </v-card>
  </CyMenu>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CyDataTableDateFilter',
  props: {
    options: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    filterOpened: false,
    startDatePicker: false,
    endDatePicker: false,
    startDate: null,
    endDate: null,
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    closeDatePicker (startOrEnd) {
      this[startOrEnd] = false
    },
    clear (date) {
      this[date] = null
    },
    apply () {
      const { $route: { name }, startDate, endDate, options: { queryParams } } = this
      const filters = { ...this.getDataTableFilters(name) }

      filters[`${queryParams[0]}[gt]`] = startDate
      filters[`${queryParams[0]}[lt]`] = endDate

      this.SET_DATA_TABLE_FILTERS({ name, filters })
      this.filterOpened = false
    },
  },
  i18n: {
    messages: {
      en: {
        endDate: 'End date',
        startDate: 'Start date',
      },
      es: {
        endDate: 'Fecha de finalización',
        startDate: 'Fecha de comienzo',
      },
      fr: {
        endDate: 'Date de fin',
        startDate: 'Date de début',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.created-date {
  .v-card__actions {
    border-top: 1px solid cy-get-color("grey");
  }
}

.date-button {
  color: cy-get-color("grey", "dark-3");
  font-weight: $font-weight-default;
}
</style>
