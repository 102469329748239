<template>
  <CyInputsDateTimeRange
    :relative-options="_.get(options, 'dateTimeRangeOptions', $static.defaultDateTimeRangeOptions)"
    :value="value"
    align-x="right"
    :mandatory="_.get(options, 'inputOptions.mandatory', true)"
    future-dates-disabled
    v-bind="_.get(options, 'inputOptions')"
    @change="changeDateTimeRange">
    <template #activator="{ on }">
      <CyButton
        theme="primary"
        variant="tertiary"
        class="owner-button"
        icon="arrow_drop_down"
        icon-append
        v-on="on">
        {{ label }}
      </CyButton>
    </template>
  </CyInputsDateTimeRange>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import CyInputsDateTimeRange from '@/components/inputs/date-time-range.vue'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyDataTableDateTimeRangeFilter',
  components: {
    CyInputsDateTimeRange,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    $static () {
      return {
        defaultFormat: 'x',
        defaultDateTimeRangeOptions: [
          {
            label: this.$tc('forms.field.timeRangeLastMinutes', 15, { minutes: 15 }),
            value: '15m',
          },
          {
            label: this.$tc('forms.field.timeRangeLastHours', 2, { hours: 2 }),
            value: '2H',
          },
          {
            label: this.$tc('forms.field.timeRangeLastHours', 12, { hours: 12 }),
            value: '12H',
          },
          {
            label: this.$tc('forms.field.timeRangeLastDays', 1),
            value: '1d',
          },
          {
            label: this.$tc('forms.field.timeRangeLastDays', 3, { days: 3 }),
            value: '3d',
          },
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
        ],
      }
    },
    queryParamNames () {
      const [begin, end] = this.options.queryParams
      return {
        begin,
        end,
      }
    },
    value () {
      const {
        $route: { name },
        options: { format },
        $static: { defaultFormat },
      } = this
      const filters = this.getDataTableFilters(name)
      return _.transform(this.queryParamNames, (returnValue, paramName) => {
        const timestampMoment = moment(filters[paramName], format || defaultFormat, true)
        if (!timestampMoment.isValid()) {
          console.error(`${paramName}: mismatch between vm.options.format and stored filter value`)
        }
        returnValue[paramName] = format
          ? timestampMoment.format(format) // eslint-disable-line you-dont-need-momentjs/format
          : timestampMoment.valueOf()
      }, {})
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    formatInputTimestamp (timestamp) {
      const { $static: { defaultFormat } } = this
      const parsedTimestamp = moment(timestamp, defaultFormat, true)
      if (!parsedTimestamp.isValid()) throw Error(`timestamp should be in format '${defaultFormat}' (Moment.js)`)
      return parsedTimestamp.format(this.options.format || defaultFormat)// eslint-disable-line you-dont-need-momentjs/format
    },
    formatFiltersInput (filters) {
      return _.transform(filters, (formattedFilters, timestamp, paramName) => {
        if (timestamp) {
          formattedFilters[this.queryParamNames[paramName]] = this.formatInputTimestamp(timestamp)
        }
      }, {})
    },
    changeDateTimeRange (input) {
      if (_.isEmpty(input)) throw Error('input object must contain begin and end timestamps')
      const { $route: { name } } = this
      const oldFilters = { ...this.getDataTableFilters(name) }
      const newFilters = this.formatFiltersInput(input.value)
      this.SET_DATA_TABLE_FILTERS({ name, filters: { ...oldFilters, ...newFilters } })
    },
  },
}
</script>
